<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M146.4,0.6H79.7c-9.4,0-17,8.1-17,18v13.7c0.4,0.3,0.9,0.5,1.6,0.5H69V18.5c0-6.4,4.8-11.6,10.7-11.6h8.7   c1.3,3.7,4.8,6.4,9,6.4h32.1c4.2,0,7.7-2.7,9-6.4h8c6.3,0,11.3,5.5,11.3,12.3v142.2c0,6.4-4.8,11.6-10.7,11.6h-15.6v6.4h15.6   c9.4,0,17-8.1,17-18V19.2C164.1,8.9,156.2,0.6,146.4,0.6z"/>
      <path class="st0" d="M134.9,163.2c0-1.8-1.4-3.2-3.2-3.2h-0.2v6.4h0.2C133.5,166.4,134.9,164.9,134.9,163.2z"/>
      <path class="st0" d="M113.3,33.3h-8c-1.3,3.7-4.8,6.4-9,6.4H64.2c-4.2,0-7.7-2.7-9-6.4h-8.7c-5.9,0-10.7,5.2-10.7,11.6v142.9   c0,6.4,4.8,11.6,10.7,11.6h67.4c5.9,0,10.7-5.2,10.7-11.6V45.6C124.6,38.8,119.5,33.3,113.3,33.3z M109.9,87.2   c-0.5,0.5-1.2,0.8-1.9,0.8s-1.4-0.3-1.9-0.8L80.7,61.7c-1-1-1-2.7,0-3.8c1-1,2.7-1,3.8,0l25.4,25.4   C110.9,84.5,110.9,86.1,109.9,87.2z M112.6,70.4c-0.5,0.5-1.2,0.8-1.9,0.8c-0.7,0-1.4-0.3-1.9-0.8L97,58.6c-1-1-1-2.7,0-3.8   c1-1,2.7-1,3.8,0l11.8,11.8C113.6,67.7,113.6,69.4,112.6,70.4z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconGlass',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

